<template>
  <div id="app">

    <llnav ref="nav" :logo="showLogo" @scrollUp="scrollUp" />

    <div id="content" ref="inner">

      <!-- Above the fold -->
      <div ref="atf" class="block">
        <div class="brg"></div>
        <div id="logoCont">
          <llogo ref="logo" :rot="rot" :logo="showLogo" />
          <div class="mtn"></div>
        </div>
      </div>


      <!-- Call to action copy -->
      <div id="imagination" class="block" style="padding-top: 5vw;">

        <p class="cta_copy" id="test">
          Founded by <llink :href="'#team'">veteran devs</llink> with a shared love of their craft, Lost Lake is an independent studio committed to making games that blaze new trails and spark your next adventure.


        <br>
        <br>

          The name Lost Lake is a promise: Discovery. Adventure. Unforgettable experiences. We believe every project should be a <llink :href="'#contact'">shared journey</llink> where imagination and expertise come together to create something magical.
        <br>
        <br>
          <llink :href="'#careers'">Join us</llink> on our adventure!
        </p>


      </div>

      <!-- Leadership section -->
      <div id="team" class="block content">
        <div class="innercont">
          <h2>Our Team.</h2>
        </div>
        <div class="innercont profileCont">
          <llprofile v-for="(p, k) in profiles" :key="k" :profile="p" />
          <!-- <llprofile v-for="(p, k) in profiles2" :key="k" :profile="p" /> -->
        </div>
        <!-- <div class="innercont">
          <llprofile v-for="(p, k) in profiles2" :key="k" :profile="p" />
        </div> -->
      </div>

      <!-- Fellow Travelers -->
      <div id="travelers" class="block content">
        <div class="innercont">
          <h2>Fellow Travelers.</h2>
          <p>Invaluable collaborators who have partnered with the Lost Lake team on our journey.</p>

        </div>
        <div class="innercont profileCont">
          <llprofile v-for="(p, k) in travelers" :key="k" :mini="true" :profile="p" />
        </div>
      </div>

      <!-- Hiring Content
      <div id="careers" class="block content">
        <div class="innercont">
          <h2>We're hiring!</h2>
        </div>

        
        <div class="jobcont">
          <div class="jobsList">
            <llink :href='"https://apply.workable.com/j/38961DD4AD"' target="_blank">Environment Artist+</llink>
          <br>
          <br>
          </div>
        </div>
      </div> -->

      <!-- Contact -->
      <div id="contact" class="block">
        <div class="innercont" style="flex-direction: column; text-align: center;">
          <img style="width: 360px; align-self: center; margin: 100px 0px;" id="logoImg" src="./assets/LostLake/logo_center2.png" />
          <div>
            <llink class="hello" style="" :color="'rgb(69, 101, 120)'" :href="'mailto:hello@lostlakegames.com'">hello@lostlakegames.com</llink>
          </div>

      <!--form -->
            <div class="sib-form" style="text-align: center; margin: auto; background-color: #000000; padding: 100px 12px 32px;">
              <div id="sib-form-container" class="sib-form-container">
                <div id="error-message" class="sib-form-message-panel" style="font-size:1em; text-align:left; font-family:inputSerif';, color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:640px;">
                  <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                    <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                      <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                    </svg>
                    <span class="sib-form-message-panel__inner-text" style="color:#ff4949; font-family:'inputSerif'">
                    Your subscription could not be saved. Please try again.
                    </span>
                  </div>
                </div>

                <div id="success-message" class="sib-form-message-panel" style="font-size:1em; text-align:left; font-family:'inputSerif';, color:#085229; background-color:#caebd9; border-radius:3px; border-color:#13ce66;width:640px;">

                  <div class="sib-form-message-panel__text sib-form-message-panel__text--center">

                    <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
                      <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                    </svg>
                    <span class="sib-form-message-panel__inner-text" style="color:#085229">
                    Your subscription has been successful.
                    </span>

                  </div>
                </div>
                <div></div>

                <div id="sib-container" class="sib-container--large sib-container--horizontal" style="text-align:center; background-color:transparent; width:640px; border-radius:3px; border-width:1px; border-color:transparent; border-style:solid;">

                  <form id="sib-form" method="POST" action="https://a262ec02.sibforms.com/serve/MUIEAPGUo1qL7FqvnPi7ugiM-h7agvmIvkcmIcrPGLoG38mV1oZnOwvbz-mCLzztbXtD_4VPWMTeAsBTO06AQOHsTX9yXOQpSiazwXdmdwp1GwYkSo_dtqD-uq8J9pQEhEC9EOn3seNgI_3UkgFWxpzmF8dLilb9bEA7kyYTbERHs6wsq4-5naJVWISSI8OECr1eh7BYgvIadG7c" data-type="subscription">
                    <div style="padding: 0;">

                      <div class="sib-form-block" style="font-size:1.3em; text-align:center; font-family:'inputSerif'; color:#ffffff; background-color:transparent;">

                        <div class="newsletterText">
                          <p>Keep up to date on our adventure!</p>
                        </div>
                      </div>
                    </div>
                    <div style="padding:0;">
                      <div class="sib-input sib-form-block">
                        <div class="form__entry entry_block">
                          <div class="form__label-row form__label-row--horizontal">

                            <div class="entry__field">
                              <input class="input" type="text" id="EMAIL" name="EMAIL" autocomplete="off" placeholder="Enter your email to receive our newsletter." style="color:#7c8f90; font-family: 'inputSerif';" data-required="true" required />
                            </div>
                          </div>

                          <label class="entry__error entry__error--primary" style="font-size:1em; text-align:left; font-family:'inputSerif'; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 8px 0;">
                      <div class="sib-form-block" style="text-align: center">

                        <button class="sib-form-block__button sib-form-block__button-with-loader" style="font-size:1em; text-align:center; font-weight:700; font-family:'inputSerif'; color:#ffffff; background-color:#283132; border-radius:3px; border-width:0px;" form="sib-form" type="submit">

                          <svg class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon" viewBox="0 0 512 512">
                            <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                          </svg>
                          Submit
                        </button>
                      </div>
                    </div>

                    <input type="text" name="email_address_check" value="" class="input--hidden">
                    <input type="hidden" name="locale" value="en">
                  </form>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import gsap from 'gsap';
import llnav from './components/LL_Nav.vue';
import llink from './components/LL_Link.vue';
import llogo from './components/LL_Logo.vue';
import llprofile from './components/LL_Profile.vue';

import leaderData from './assets/data/Leaders.json';
import travelerData from './assets/data/Travelers.json';
// import lljob from './components/LL_Job.vue';
// import llgame from './components/LL_Game.vue';

export default {
  name: 'App',
  components:{
    llnav,
    llink,
    llogo,
    llprofile,
    // lljob
    // llgame
  },
  mounted(){
    let scrolled = false;
    let rect = this.$refs['atf'].getBoundingClientRect();
    let logo = this.$refs['logo'].$el;
    let offset = (window.innerHeight / 2) - (logo.offsetHeight / 2);
    let blocks = document.querySelectorAll('.block');
    let blockArr = [];

    this.careers = process.env.jobList;

    logo.style.top = offset + "px";

    window.addEventListener('resize', () =>{
      logo.style.top =  offset+ "px";
    })

    gsap.from(this.$refs['nav'].$el, .9, {autoAlpha: 0, delay: .3});

    for(let i = 0; i < blocks.length; i++){
      blockArr.push({
        val: blocks[i].id,
        top: blocks[i].getBoundingClientRect().top
      });
    }

    let cur = "";
    let curel = null;
    let els = document.querySelectorAll('nav .border');

    this.$refs['inner'].addEventListener('scroll', (e) => {
      // SCROLLING ANIMATIONS

      e.preventDefault();
      let st = e.target.scrollTop;
      let currentSec = blockArr.find(e => (e.top + window.innerHeight - 10) >= st);

      if(!currentSec) return;

      //reset navigation animation
      if(!currentSec.val && cur){
        cur = "";
        gsap.to(curel, .3, {opacity: 0});
      }

      if(currentSec.val == 'travelers'){
        currentSec.val = 'team';
      }

      // navigation animations
      if(currentSec.val && cur !== currentSec.val){
        cur = currentSec.val;
        curel = document.querySelectorAll('#'+cur + 'lnk .border');

        for(let i = 0; i <= els.length; i++){
          if(els[i]){
            gsap.to(els[i], .3, {opacity: 0});
          }
        }

        gsap.to(curel, .3, {opacity: 1});
      }

      // Rotate the logo.
      if(st < 4){
        this.rot = 0;
      }else{
        this.rot = 45;
      }

      // Fade the background and set the scroll to zero when scrolled up
      if(st < 210){
        if(scrolled){
          this.$refs['inner'].scrollTo(0,0);
          scrolled = false;
        }
        logo.style.top = offset+"px";
        gsap.to('.brg', .4, {autoAlpha: 1});
      }else{
        if(st > offset + 100){
          logo.style.top = (offset+(window.innerHeight/2)-75) - st+"px";
        }
        scrolled = true;
        gsap.to('.brg', .4, {autoAlpha: 0});
      }

      // Hide the logo on scroll down.
      if(st < rect.height/2 + 50){
        gsap.to(logo, .3, {autoAlpha: 1});
        // gsap.to(logo, .3, {autoAlpha: 1, paddingRight: 0});
        // gsap.to("#content", .3, {'margin-right': -17});
        this.showLogo = false;
      }else{
        gsap.to(logo, .3, {autoAlpha: 0});
        // gsap.to(logo, .3, {autoAlpha: 0, paddingRight: 100});
        // gsap.to("#content", .3, {'margin-right': 0});
        this.showLogo = true;
      }

      // Add scrollbar and change navigation background color.
      if(st < rect.height + 445){
        gsap.to('nav', .6, {backgroundColor: "rgba(0,0,0,.25)"});
      }else{
        gsap.to('nav', .5, {backgroundColor: "rgba(0,0,0,.5)"});
      }

      return false;
    });
  },
  methods: {
    scrollUp(){
      this.$refs['inner'].scrollTo(0,0);
    }
  },
  data: function(){
    return {
      rot: 0,
      clicked: false,
      showLogo: false,
      careers: [],
      travelers: travelerData,
      profiles: leaderData
    }
  }
}
</script>

<style>

@media(max-width: 768px){
  .sib-container--large{
    width: 390px!important;
    font-size:.7em!important;
  }
  .cta_copy{
    font-size: 16px !important;
    width: 340px !important;
  }
  .innercont{
      /* width: 360px !important; */
    }
  .innercont h2{
      width: 400px!important;
      margin-top: 6px !important;
      font-size: 20px!important;
      /* line-height: 1.4; */
      /* flex: 1; */
      /* font-family: 'inputSerif'; */
      margin-bottom: 12px!important;
      padding-right: 0px!important;
      /* display: block; */
    }

  .profileCont{
    flex-direction: column;
    width: 400px!important;
    margin: 12px 12px!important;
  }
  .newsletterText{
    font-size: .75em!important;

  }

  .jobsList{
    padding: 0px 0px 0px 20px !important;
    font-family: 'inputSerif';
  }

}


  .newsletterText{
    font-family:"inputSerif";
    font-size: 1em;
  }
.mtn, .brg{
  width: 100%;
  background-size: cover !important;
  }
  .mtn{
    position: sticky;
    height: 100vh;
    z-index: 9996;
    bottom: 0px;
    background: url('./assets/LostLake/bangla.png') no-repeat center center;
    /* padding-bottom: -125px; */
    }
  .brg{
    position: absolute;
    height: 125%;
    top:0px;
    z-index: 9997;
    background: url('./assets/LostLake/bridge.jpg') no-repeat center center;
    }
.block{
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color:#000;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:#FFF;
  display: flex;
  padding-bottom: 100px;
  box-sizing: border-box;
  align-items: center;
  }
  .block:first-child{
    margin-bottom: 450px;
    padding-bottom: 200px;
    }

*{
  margin: 0px;
  padding: 0px;
  }
html, body{
  height: 100%;
  width: 100%;
  background-color: #000;
  }

#contact{
  font-family: 'inputSerif';
  font-size: 3em;
  }

#logoCont{
  /* height: 163vh; */
  /* height: 1684px; */
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  }
#top{
  width: 100px;
  height: 100px;
  /* background-color:#00F; */
  }
#app{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  }
  #content{
    overflow-y: scroll;
    /* margin-right: -17px; */
    overflow-x: hidden;
    }

  .cta_copy{
    /* margin: 650px 0 100px 0; */
    color:#FFF;
    font-family: 'inputSerif';
    font-size: 2em;
    font-weight: 900;
    color: rgb(69, 101, 120);
    line-height: 1.6;
    /* width: 90vw; */
    /* width: 1200px; */
    max-width: 1200px;
    width: 90vw;
    }
    .innercont h2{
      width: 900px;
      margin-top: 100px;
      font-size: 54px;
      line-height: 1.4;
      flex: 1;
      font-family: 'inputSerif';
      margin-bottom: 100px;
      padding-right: 380px;
      display: block;
      }
    .innercont{
      display: flex;
      max-width: 1200px;
      width: 90vw;
      flex-wrap: wrap;
      }

    #scrollbar{
      opacity: 0;
      width:100px;
      height: 90%;
      position: fixed;
      right:0px;
      z-index:9997;
      bottom: 0px;
      }

    #logoScroll{
      height: 100px;
      width:100%;
      background-image: url('./assets/LostLake/logo_center2.png');
      background-size: cover;
      position: absolute;
      z-index:9999;
      }

    .jobcont{
      width: 100%;
      max-width: 1400px;
      padding: 0px 100px;
      }
    .jobsList{
      font-family: 'inputSerif';
      padding: 0px 100px;
      font-size:1em;
      }

      #team{
        padding-bottom: 20px;
      }f

      #travelers h2{
        text-align: center;
        padding: 0px;
      }
      #travelers .profileCont, #travelers .innercont{
        width: auto;
      }

      #travelers .profile{
        flex-basis: 25%;
      }

      #travelers .innercont p{
        text-align: justify;
        font-family: 'inputSerifCondensed';
        font-size: 1em;
        margin-bottom: 60px;
        padding-left: 10px;
        line-height: 1.7em;
      }
      #travelers h2{
        font-size:38px;
        margin: 25px 10px;
      }

</style>
