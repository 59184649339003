<template>
  <div :class="{'mini': mini, 'profile': true}">
    <div class="inner">
      
      <div :style="{ backgroundImage: `url('${i}')`, 'background-position-x': bgpos+'px'}" class="img" margin: auto/>
      <div class="content" >

        <div v-if="mini" class="top">
          <h3 :style="{'margin-top': mar+'px', width: this.wid}">{{ profile.name }}</h3>
        </div>
        <h3 v-else :style="{'margin-top': mar+'px', width: this.wid}">{{ profile.name }} <div v-if="!game">{{ profile.title }}</div></h3>

        <p v-if="!mini"><span v-html="profile.description" /></p>
        <div v-if="!game && mini" class="profileTitle">{{ profile.title }}</div>
        <div v-if="profile.socials" class="socials">
          <div
          v-for="(social, idx) in profile.socials"
          :key="idx"
          class="socialBtn"
          :class="{
            lnBtn: social.type == 'linkedIn',
            webBtn: social.type == 'webAddress',
            twtBtn: social.type == 'twitter'
          }"
          @click="open(social.link)" />
        </div>

        <span v-if="mini" class="blurb">{{profile.blurb}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'lprofile',
  props: {
    bgpos: {
      type: Number,
      default: 0
    },
    game: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Object,
      default: () =>{}
    },
  },
  data() {
    return{
      i: null,
      mar: null,
      wid: null
    }
  },
  mounted(){
    if(this.game){
      this.i = require('../assets/LostLake/games/'+this.profile.img);
      this.mar = -50;
      this.wid = '100%';
    } else {
      if(!this.mini){
        this.i = require('../assets/LostLake/profile/'+this.profile.img);
        this.mar = -100;
        this.wid = '30px';
      }else{
        let fileName = this.profile.name.replace(/\s/g, '');
        if(this.profile.socials[0].link.length == 28){
          this.profile.socials = false;
        }
        if(this.profile.name == 'Devon Cady-Lee' || this.profile.name == 'Yeonsoo Julian Kim'){
          this.wid = '180px';
        }
        try{
          this.i = require(`../assets/LostLake/travelers/${fileName}.jpg`);
        } catch(e){
          this.i = require('../assets/LostLake/travelers/placeholder.png');
        }
      }
    }
    gsap.from(this.$el, 1, {opacity: 0, delay: this.$vnode.key - this.$vnode.key/1.4})
  },
  methods: {
    open(link){
      window.open(link, '_blank');
    }
  }
}
</script>

<style scoped>
@media(max-width: 768px){
  p{
  padding-right: 12px!important;
  /* padding-left: 12px!important; */
  padding-bottom: 36px!important;
  }
  .profile{
    width: 400px;
    margin-bottom: 32px!important;
  }
  .mini{
    width: 200px !important;
    margin: 10px;
    display: flex;
    position: relative;
  }
  .mini .top{
    display: inline-block !important;
  }
}
.content{
  padding-left: 20px;
}
.mini .content{
  padding-left: 0px;
  padding-top: 10px;
}

.mini{
  width: 200px !important;
  display: flex;
  position: relative;
}
.mini h3{
  font-size: 22px;
  bottom: 5px;
  left: 10px;
  position: absolute;
}
.mini .socials{
  float: right;
  width: 22px;
  padding-right: 5px;
  margin: 0px;
}

.mini .profileTitle{
  float: left;
  font-family: 'inputSerif';
  font-size: 11px;
  padding-top: 5px;
  padding-left: 5px;
  min-height: 20px;
  line-height: 30px;
}
.mini span{
  padding-top: 10px;
  /*display: inline-block;*/
}
.mini .top{
  margin-left: -8px;
  margin-top: -10px;

  width: 100%;
  height: 310px;
  border: none;
  position: absolute;
}
.mini h3 div{
  float: left;
  font-size: 13px;
  width: 160px;
  margin-left: -18px;
  z-index: 9998;
}
.mini .img{
  height: 309px !important;
}
.mini .lnBtn{
  width: 20px;
  height: 20px;
  background-size: contain !important;
}
.mini .blurb{
  display: flex;
  width: calc(100% - 10px);
  place-items: bottom;
  margin-top: 10px;
  padding: 10px 5px 0px 5px;
  font-family: 'inputSerifCondensed';
  font-size: 12px;
  line-height: 1.5em;
}


.profile{
  width: 400px;
  margin-bottom: 32px!important;
}
.img{
  height: 580px;
  width: 100%;
  background-size: cover;
}
h3{
  width: 30px;
  font-size: 38px;
  margin-top: -88px;
  font-family: 'inputSerif';
}
h3 div {
  padding-top: 20px;
  width: 350px;
  font-size: 20px;
}
p{
  padding-right: 45px;
  font-size: 1em;
  line-height: 1.7;
  margin-top: 24px;
  font-family: 'inputSerifCondensed';
  white-space: pre-wrap;
}

.socials{
  float: left;
  width: 100%;
  padding-top: 7px;
  margin-top: 7px;
  }
.socialBtn{
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background:#000;
  margin-right: 16px;
  }
  .lnBtn{ background: url('../assets/LostLake/linkedin_sm.png') !important; }
  .webBtn{ background: url('../assets/LostLake/website_sm.png') !important; }
  .twtBtn{ background: url('../assets/LostLake/twitter_sm.png') !important; }
</style>
